<template>
  <div class="auth">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack()">实名认证</div>
    <div class="card">
        <div class="step_lines">
            <span class="active">1</span>
            <div :class="[ 'line', step>1 ? 'active': '']"></div>
            <span :class="[ step>1 ? 'active': '']">2</span>
            <div :class="[ 'line', step>2 ? 'active': '']"></div>
            <span :class="[ step>2 ? 'active': '']">3</span>
        </div>
        <div class="step_text">
            <span class="active">企业认证</span>
            <span :class="[ step>1 ? 'active': '']">法人人脸识别</span>
            <span :class="[ step>2 ? 'active': '']">认证完成</span>
        </div>
    </div> 
    <form class="auth-card" v-if="step == 1">
        <div class="catalog">
            <p>企业名称</p>
            <input type="text" :value="companyName" disabled>
        </div>
        <div class="catalog">
            <p>统一社会信用代码</p>
            <input type="text" :value="creditCode" disabled>
        </div>
        <div class="catalog">
            <p>法人姓名</p>
            <input type="text" placeholder="输入法人姓名" v-model="legalPersonName">
        </div>
        <div class="catalog">
            <p>法人身份证</p>
            <input type="text" placeholder="输入法人身份证号码" v-model="idcard" maxlength="18">
        </div>
        
        <!-- <div class="catalog">
            <p>&nbsp;</p>
            <div class="add_pic">
                <img src="@/assets/img/add_big.png" alt="">
                <p>企业法人营业执照</p>
            </div>
        </div> -->
       <div class="footer-btn">
            <div class="auth-btn" @click="setStep(2)">下一步</div>
        </div>

    </form>
    <form class="auth-card" v-if="step == 2">
        <div class="catalog">
            <p>姓名</p>
            <input type="text" :value="legalPersonName" disabled>
        </div>
        <div class="catalog">
            <p>身份证</p>
            <input type="text" :value="idcard" disabled>
        </div>
        <div class="catalog" style="align-items: flex-start;">
            <p>认证视频</p>
            <div class="add_pic">
                <img v-if="video" src="@/assets/img/tongguo.png" alt="">
                <img v-else src="@/assets/img/add_big.png" alt="" @click="addLink()">
                <p>{{ video ? '法人人脸识别视频录制完成' : '点击录制动态视频'}}</p>
            </div>
        </div>
       <div class="footer-btn">
            <div class="auth-btn" @click="setStep(1)">上一步</div>
            <div v-if="video" class="auth-btn" @click="submit()">提交认证</div>
            <div v-else class="auth-btn disabled-btn">提交认证</div>
        </div>

    </form>
    <div class="auth-card" v-if="step == 3">
        <div class="pass">
            <img src="../../assets/img/tongguo.png" alt="">
            <p class="p1">&nbsp;&nbsp;&nbsp;恭喜您！</p>
            <p class="p2">您的实名认证已通过</p>
        </div>
        <div class="footer-btn">
            <router-link to="/auth" class="auth-btn">返回</router-link>
        </div>
    </div>
  </div>
</template>
<script>
import { ref,watch,reactive,toRefs,toRaw } from 'vue';
import {apiGetEnterInfo,apiGxiAuth} from '../../api/axios';
import { Toast } from 'vant';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
export default {
   setup() {
       let store = useStore();
       const step = ref(1);
       const video = ref(false);       
        const state = reactive({
            reqType: 'C0101',
            idtype: '01',
            companyName: '',
            creditCode: '',
            legalPersonName: '',
            idcard: '',
            voiceSession: ''
        });

        let loc = location.href;
        let n2 = loc.indexOf("=gxr");//取得=号的位置
        let urlTimestamp = '';
        if(n2 > -1) {
            urlTimestamp = decodeURI(loc.substr(n2+1, 16));//从=号后面的内容
        }

        if(localStorage.getItem("setRealNameAuthLink")){
            let realLink = JSON.parse(localStorage.getItem("setRealNameAuthLink"));  
            step.value = 2;
            state.legalPersonName = realLink.legalPersonName;
            state.idcard = realLink.idcard;
            state.voiceSession = realLink.voiceSession;
            if(urlTimestamp===realLink.timestamp) {
                video.value = true;
            }
        };        
        apiGetEnterInfo({}).then(res => {                   
             if(res.code == '1'){
                 state.companyName = res.info.enterBaseInfo.enterName;
                 state.creditCode = res.info.enterRegisterInfo.uniscid;
              }
              else {
                Toast(res.msg);
              }
          });

        const addLink = () =>{
            let time = Date.parse(new Date()).toString();
            // let linkStamp = new Date().getTime().toString;
            let obj = {
                reqType: '31301',
                timestap: time,
                username: state.legalPersonName,
                idcard: state.idcard,
                redirectUrl: loc + '?=gxr' + time
            }
            apiGxiAuth(obj).then(res => {                 
                    if(res.code == '1'){
                        if(res.info.c_code == '0') {
                            if(res.info.c_obj.data == null) {
                                // Toast('请检查姓名与身份证号码是否正确,或稍后再试');
                                Toast('请输入正确的姓名和身份证！');
                                return;
                            }
                            let linkobj = {
                                legalPersonName: state.legalPersonName,
                                idcard: state.idcard,
                                voiceSession: res.info.c_obj.data.voiceSession,
                                timestamp: 'gxr' + time
                            }
                            localStorage.setItem("setRealNameAuthLink", JSON.stringify(linkobj));
                            window.location.href = res.info.c_obj.data.url;
                            
                        }else {
                            Toast.fail(res.info.c_msg);
                        }
                    }
                    else {
                        Toast.fail(res.msg);
                    }
            });
        };
        const setStep = (val) =>{
            if(!state.legalPersonName) {
                Toast('请先输入法人姓名');
                return
            }
            if(state.idcard.length<15) {
                Toast('请先输入正确格式的法人身份证');
                return
            }
            step.value = val;
        };
        let lf = store.state.loginInfo;
        //提交认证
        const submit = () =>{
            if(!state.voiceSession) {
                Toast('请先录制认证动态视频');
                return
            }
            let time = Date.parse(new Date()).toString();
            let obj = toRaw(state);
            obj.timestap = time;
            // console.log(obj)
            Toast.loading({
                duration: 0,
                message: '提交认证中...',
                forbidClick: true,
                loadingType: 'spinner',
            });
             apiGxiAuth(obj).then(res => {
                    Toast.clear();                 
                    if(res.code === '1'){                        
                        if(res.info.c_code === '0') {                            
                            step.value = 3;
                            lf.isOrgAuth = '1';
                            localStorage.setItem("gxrToken", JSON.stringify(lf));
                            store.dispatch('setToken',lf);
                        }else {
                            Toast.fail(res.info.c_msg);
                        }                        
                    }
                    else {
                        Toast.fail(res.msg);
                    }
            });

        };

        let router = useRouter();
        const goBack = () =>{
            router.push({
                    path:'/auth',
                }); 
        };
     return {
         step,
         video,
         ...toRefs(state),
         addLink,
         setStep,
         submit,
         goBack
     }
  }

}
</script>
<style lang="less" scoped>
.auth {
    padding-top: 12.5vw;
    // background-color: #fff;
    .step_lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4vw;
        span {
            text-align: center;
            line-height: 6vw;
            width: 6vw;
            height: 6vw;
            border-radius: 50%;
            color: rgb(128, 127, 127);
            background-color: #ccc;
        }
        .line {
            border-top: 1px solid #ccc;
            flex: 1;
        }
        .active {
        color: #fff;
        background-color: #ff9900;
        border-color: #ff9900;
        }
    }
    .step_text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 4vw;
        margin-top: 3px;
        color: #666;
        .active {
            color: #ff9900;
        }
    }
    
    .auth-card {
        margin: 4vw;
        background-color: #fff;
        border-radius: 1.5vw;
        padding: 4vw;
        .catalog {
            display: flex;
            align-items: center;
            
            p:first-child {
                width: 24vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                
                .key {
                    color: #ff6600;
                }
            }
            // p:last-child {
            //     width: 57vw;
            //     font-size: 4vw;
            //     padding-left: 4vw;

            // }
            .add_pic {
                width: 57vw;
                height: 39vw;
                font-size: 4vw;
                // padding-left: 4vw;
                margin-left: 4vw;
                border-radius: 1vw;
                background-color: #f4f8fe;
                text-align: center;
                img {
                    width: 13vw;
                    margin: 6vw 0 2vw;
                }
                p {
                    color: #336699;
                    width: 35vw;
                    margin-left: 11vw;
                }

            }
            input {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 57vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }
            ::-webkit-input-placeholder {
              color: #7f9abb;
            }
            ::-moz-placeholder {
              color: #7f9abb;
            }
            :-ms-input-placeholder {
              color: #7f9abb;
            }
            :-moz-placeholder {
              color: #7f9abb;
            }

        }
        .pass {
            text-align: center;
            margin: 4vw 0;
            img {
                width: 15vw;
            }
            .p1 {
                font-weight: 600;
                font-size: 5.5vw;
            }
            .p2 {
                font-size: 4vw;
            }
        }
        .footer-btn {
            display: flex;
            justify-content: center;
            .auth-btn {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4vw;
            padding: 1.5vw;
            border-radius: 7vw;
            border: 0;
            margin: 5vw 2vw 0;
            width: 25vw;
            text-align: center;
            letter-spacing: 1px;
            }
            .disabled-btn {
                background: linear-gradient(to right, #999, #666);
                opacity: 0.6;
            } 
    

        }
        
    }

    
}
</style>
